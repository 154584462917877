<template>
  <ValidationProvider
    :rules="validationRules"
    class="question latlong column"
    :class="width"
    tag="div"
    v-slot="{ errors, dirty, valid }"
    v-if="!reviewMode"
  >
    <div class="question-text">
      <p class="question-text__code" v-if="showQuestionCode">
        {{ question.code }}
      </p>
      <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
      <p class="help" v-else>{{ questionHelp }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <b-field
          :type="{ 'is-danger': errors[0], 'is-success': dirty && valid }"
        >
          <b-taginput
            v-model="tags"
            :data="addresses"
            autocomplete
            field="name"
            open-on-focus
            attached
            :loading="isFetching"
            :maxtags="`${qOpts.max}`"
            :has-counter="false"
            icon="map-marker"
            :placeholder="$t('lat_lng.placeholder')"
            :create-tag="convertLatLng"
            :before-adding="validateAddress"
            @add="onAdd"
            @remove="onRemove"
            @typing="searchAddress"
          >
            <template slot-scope="props">
              <b-icon size="is-small" icon="map-marker"> </b-icon>
              {{ props.option.description }}
            </template>
            <template #empty>
              {{ $t('lat_lng.empty_address') }}
            </template>
            <template #footer>
              <span class="is-pulled-right">
                <img
                  src="/powered_by_google_on_white.png"
                  alt="Powered by Google"
                />
              </span>
            </template>
          </b-taginput>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
  <div class="question" v-else>
    <div class="is-flex is-align-items-center">
      <div class="question-text has-text-weight-bold column p-0">
        <p class="question-text__code" v-if="showQuestionCode">
          {{ question.code }}
        </p>
        <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      </div>
      <b-button type="is-text" @click="$emit('edit')">
        <b-icon icon="pencil" type="is-primary"></b-icon>
      </b-button>
    </div>
    <div class="question-content">
        <b-taginput
          v-model="currentValue"
          :data="addresses"
          autocomplete
          field="name"
          open-on-focus
          attached
          :loading="isFetching"
          :maxtags="`${qOpts.max}`"
          :has-counter="false"
          :create-tag="convertLatLng"
          :before-adding="validateAddress"
          :closable="false"
          :readonly="loading"
        >
        </b-taginput>
    </div>
  </div>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { placeDetails, placeSearch } from "@/api/google";
// only uncomment in development ENV
// import { placeDetails, placeSearch } from "@/api/google.local";

export default {
  name: "LatLong",
  mixins: [QuestionMixin],
  data() {
    return {
      addresses: [],
      tags: [],
      isFetching: false,
      location: {},
      countries: 'country:jp'
    };
  },
  computed: {
		currentValue() {
			return this.value[this.question.qid];
		}
	},
  created() {
    this.handlerParamComponents()
  },
  watch: {
    currentValue (newVal, oldVal) {
      if (oldVal === undefined && !isEmpty(newVal)) {
				this.tags = newVal;
			}
    },
	},
  methods: {
    onRemove() {
      this.$emit("input", { [this.question.qid]: this.tags });
    },
    async onAdd(address) {
      await placeDetails({
        place_id: address.place_id,
      })
        .then((response) => {
          const { data } = response;
          if (data.status !== "OK") throw new Error("Invalid status");
          this.tags = this.tags.map((x) =>
            x.id === address.id
              ? {
                  ...{ location: data.results[0]?.geometry?.location || {} },
                  ...x,
                }
              : x
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$emit("input", { [this.question.qid]: this.tags });
        });
    },
    convertLatLng(address) {
      const { place_id, description } = address;
      return {
        placeId: place_id,
        name: description,
      };
    },

    validateAddress(address) {
      const isDuplicated = this.tags.some(
        (el) => el.placeId === address.place_id
      );
      return !isDuplicated;
    },

    searchAddress: debounce(function(text) {
      if (!text) {
        return;
      }
      this.isFetching = true;
      placeSearch({
        input: text,
        language: this.$i18n.locale,
        components: this.countries,
      })
        .then((response) => {
          this.addresses = response.data.predictions;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 1000),

    handlerParamComponents() {
      const countryOptions = this.question.options?.country_selected?.config || [];
      if (countryOptions.length > 0) {
        this.countries = countryOptions.map(country => "country:" + country.iso2).join("|")
      }
    }
  },
  mounted () {
    if (!isEmpty(this.currentValue)) {
      this.tags = this.currentValue;
    }
  }
};
</script>

<style lang="scss"></style>
